import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDocument } from "../../firebase files/hooks/useDocument.js";
import { useAuthContext } from "../../firebase files/hooks/useAuthContext.js";
import { useFirestore } from "../../firebase files/hooks/useFirestore.js";
import { useCollection } from "../../firebase files/hooks/useCollection.js";
import {
  projectFirestore,
  projectStorage,
} from "../../firebase config/config.js";
import CorporateTwoClients from "../../templates/corporate/corporateTwo/corporateTwoComponents/CorporateTwoClients/CorporateTwoClients.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useRef } from "react";
import Popup from "../../default components/Popup.js";
import ReactSwitch from "react-switch";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaHeart } from "react-icons/fa";
import TextEditor from "../../default components/TextEditor.jsx";
import Rating from "@mui/material/Rating";
import { Box, TextField, Button } from '@mui/material';
import StarIcon from "@mui/icons-material/Star";
import OwlCarousel from "react-owl-carousel";


import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import { FaEdit } from "react-icons/fa";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProductDetails.css";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(rating) {
  return `${rating} Star${rating !== 1 ? "s" : ""}, ${labels[rating]}`;
}
const ProductDetails = () => {
  // Scroll to the top of the page whenever the location changes start
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Start firebase code
  const { user } = useAuthContext();

  const { productId } = useParams();

  const { document: appTypeDocument, error: appTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: functionTypeDocument, error: functionTypeError } =
    useDocument("settings", "FunctionType");

  const { updateDocument } = useFirestore("products");

  const { deleteDocument } = useFirestore("products");

  const { documents: allproducts, error: allproductsError } = useCollection(
    "products"
  );

  const { document, error } = useDocument(
    "products",
    productId
  );

  const { document: currencyDocument, error: currencyDocumentError } = useDocument("settings", "currency");

  // End Firebase code

  // delete popup
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const fileInputRef = useRef(null);
  const [checked, setChecked] = useState();
  const [editedName, setEditedName] = useState("");
  const [productName, setProductName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedPrice, setEditedPrice] = useState("");
  const [isEditingPrice, setIsEditingPrice] = useState(false);
  const [productPrice, setProductPrice] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [isEditingRating, setIsEditingRating] = useState(false);
  const [editedRating, setEditedRating] = useState("");
  const [productDiscount, setProductDiscount] = useState("");
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [editedDiscount, setEditedDiscount] = useState("");
  const [AddOption, setAddOption] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [selctedOption, setOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [isAddOption, setIsAddOption] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [editedShortDescription, setEditedShortDescription] = useState("");
  const [productShortDescription, setProductShortDescription] = useState("");
  const [isEditingShortDescription, setIsEditingShortDescription] =
    useState(false);
  const [productImages, setProductImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [rating, setRating] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [editedExternalUrl, setEditedExternalUrl] = useState("");
  const [isEditingProductUrl, setIsEditingExternalUrl] = useState(false);
  const [isHeartClicked, setIsHeartClicked] = useState({});
  const [checkedTopSelling, setCheckedTopSelling] = useState(false);
  const [checkedPopular, setCheckedPopular] = useState(false);
  const [checkedNewArrivals, setCheckedNewArrivals] = useState(false);

  useEffect(() => {
    // Fetch document data and set editedName
    if (document) {
      setProductName(document.name);
      setEditedName(document.name);
      setProductPrice(document.price);
      setEditedPrice(document.price);
      setProductDescription(document.description);
      setEditedDescription(document.description);
      setProductShortDescription(document.short_description);
      setEditedShortDescription(document.short_description);
      setProductDiscount(document.discount);
      setEditedDiscount(document.discount);
    }
  }, [document]);

  //START CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE
  useEffect(() => {
    if (document) {
      const localStorageKey = `heartStatus_${document.id}`;
      const storedHeartStatus = localStorage.getItem(localStorageKey);
      if (storedHeartStatus !== null) {
        setIsHeartClicked({ [document.id]: storedHeartStatus === "true" });
      } else {
        setIsHeartClicked({ [document.id]: false });
      }
    }
  }, [document]);

  // Update toggleHeartStatus function
  const toggleHeartStatus = (productId) => {
    const newHeartStatus = !isHeartClicked[productId];
    setIsHeartClicked((prevState) => ({
      ...prevState,
      [productId]: newHeartStatus,
    }));
    localStorage.setItem(`heartStatus_${productId}`, newHeartStatus);
  };

  //END CODE FOR HEART CLICK AND STORE IN BROWSER STORAGE

  if (error) {
    return (
      <div className="error" style={{ marginTop: "100px" }}>
        Data Not Found
      </div>
    );
  }
  if (!document) {
    return <div className="loading"></div>;
  }

  // START CODE FOR ADD NEW IMAGES
  const handleAddMoreImages = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsConfirmVisible(true);
    }
  };

  const handleConfirmUpload = async () => {
    if (selectedImage) {
      setIsUploading(true);
      try {
        const file = fileInputRef.current.files[0];
        const storageRef = projectStorage.ref(
          `product_img/${productId}/${file.name}`
        );
        await storageRef.put(file);

        const downloadURL = await storageRef.getDownloadURL();
        const updatedImages = [...(document.images || []), downloadURL];

        await projectFirestore
          .collection("products")
          .doc(productId)
          .update({ images: updatedImages });
        setProductImages(updatedImages);

        setSelectedImage(null);
        setIsConfirmVisible(false);
        setIsUploading(false); // Set isUploading to false when upload completes
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const images = document.images || [];

  // START CODE FOR EDIT FIELDS
  const handleEditClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setIsEditingName(true);
        break;
      case "rating":
        setIsEditingRating(true);
        break;
      case "price":
        setIsEditingPrice(true);
        break;
      case "discount":
        setIsEditingDiscount(true);
        break;
      case "shortdescription":
        setIsEditingShortDescription(true);
        break;
      case "producturl":
        setIsEditingExternalUrl(true);
        break;
      case "description":
        setIsEditingDescription(true);
        break;
      default:
        break;
    }
  };

  // Function to handle saving the edited field
  const handleSaveClick = async (fieldName) => {
    try {
      switch (fieldName) {
        case "name":
          await updateDocument(productId, { name: editedName }); // Update the name in Firestore
          setIsEditingName(false); // Close the editing mode
          break;
        case "price":
          await updateDocument(productId, { price: editedPrice }); // Similarly, update the price in Firestore
          setIsEditingPrice(false);
          break;
        case "discount":
          await updateDocument(productId, { discount: editedDiscount });
          setIsEditingDiscount(false);
          break;
        case "shortdescription":
          await updateDocument(productId, { short_description: editedShortDescription });
          setIsEditingShortDescription(false);
          break;
        case "externalurl":
          await updateDocument(productId, { external_url: editedExternalUrl });
          setIsEditingExternalUrl(false);
          break;
        case "description":
          await updateDocument(productId, { description: editedDescription });
          setIsEditingDescription(false);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };


  const handleCancelClick = (fieldName) => {
    switch (fieldName) {
      case "name":
        setEditedName(document.name);
        setIsEditingName(false);
        break;
      case "rating":
        setEditedRating(document.rating);
        setIsEditingRating(false);
        break;
      case "price":
        setEditedPrice(document.price);
        setIsEditingPrice(false);
        break;
      case "discount":
        setEditedDiscount(document.discount);
        setIsEditingDiscount(false);
        break;
      case "shortdescription":
        setEditedShortDescription(document.short_description);
        setIsEditingShortDescription(false);
        break;
      case "externalurl":
        setEditedExternalUrl(document.external_url);
        setIsEditingExternalUrl(false);
        break;
      case "description":
        setEditedDescription(document.description);
        setIsEditingDescription(false);
        break;
      // Add more cases for other fields if needed
      default:
        break;
    }
  };
  // END CODE FOR EDIT FIELDS

  // START CODE FOR SHARE SERVICE
  const shareURL = async () => {
    try {
      await navigator.share({
        title: "Share this service",
        text: "Check out this service!",
        url: window.location.href,
      });
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };
  // END CODE FOR SHARE PRODUCT

  // START CODE FOR CHANGE PRODUCT STATUS
  const handleStatusToggleChange = (productId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Update the status using updateDocument hook
    updateDocument(productId, { status: newStatus })
      .then(() => {
        setChecked(newStatus === "active");
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE PRODUCT STATUS

  // delete document code
  const handleDeleteProduct = async () => {
    try {
      // Open the delete confirmation popup
      setIsDeletePopupOpen(true);
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };

  const handleConfirmDeleteProduct = async () => {
    try {
      await deleteDocument(productId);
      // Redirect to the services page
      window.location.href = "/services";
    } catch (error) {
      console.error("Error deleting service:", error);
      // Handle the error as needed
    }
  };

  // START CODE FOR CATEGORY OPTIONS
  const handleAddOptionClick = () => {
    setIsAddOption(true);
  };
  const handleSaveAddOption = async () => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Add the new option to the array
      const updatedOptions = [...currentOptions, AddOption];

      // Update the options array in Firestore
      await projectFirestore
        .collection("products")
        .doc(productId)
        .update({ options: updatedOptions });

      // Reset the AddOption state and close the input field
      setAddOption("");
      setIsAddOption(false);
    } catch (error) {
      console.error("Error adding new option:", error);
      // Handle the error as needed
    }
  };
  const handleCancelAddOption = () => {
    setIsAddOption(false);
  };
  const handleOptionChange = (index) => {
    setSelectedOption(!selectedOption);
    setIsOptionSelected(true);
    setSelectedOptionIndex(index);
  };
  // Fetch options array from the document state
  const options = document.options || [];
  // delete option
  const handleDeleteOption = async (index) => {
    try {
      // Get the current options array from the document
      const currentOptions = document.options || [];

      // Remove the selected option from the array
      const updatedOptions = currentOptions.filter((_, i) => i !== index);

      // Update the options array in Firestore
      await projectFirestore
        .collection("products")
        .doc(productId)
        .update({ options: updatedOptions });
    } catch (error) {
      console.error("Error deleting option:", error);
      // Handle the error as needed
    }
  };
  // END CODE FOR CATEGORY OPTIONS

  // RATING CODE START
  const handleRatingChange = (newValue) => {
    // Prevent setting the rating to null
    const newRating = newValue !== null ? newValue : rating;

    setRating(newRating);
    // Update the rating field in the Firebase document
    updateDocument(productId, { rating: newRating })
      .then(() => console.log("Rating updated successfully"))
      .catch((error) => console.error("Error updating rating:", error));
  };
  // END RATING CODE

  // START CODE FOR CHANGE PRODUCTS POPULARITY
  const handlePopularityToggleChange = (fieldName, currentStatus) => {
    const newStatus = !currentStatus; // Toggle the current boolean status
    // Update the status in Firestore
    updateDocument(productId, { [fieldName]: newStatus })
      .then(() => {
        switch (fieldName) {
          case "topSellingProducts":
            setCheckedTopSelling(newStatus);
            break;
          case "popularProducts":
            setCheckedPopular(newStatus);
            break;
          case "newArrivals":
            setCheckedNewArrivals(newStatus);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.error("Error updating product status:", error);
      });
  };
  // END CODE FOR CHANGE PRODUCTS POPULARITY

  return (
    <>
      <div className="services-details-parent-div">
        <Popup
          showPopupFlag={isDeletePopupOpen}
          setShowPopupFlag={setIsDeletePopupOpen}
          msg="Are you sure you want to delete this service?"
          setPopupReturn={(confirmed) => {
            if (confirmed) {
              handleConfirmDeleteProduct();
            }
          }}
        />
        <div className="prducts-details-top-suggestion-parent">
          <OwlCarousel
            items={1}
            loop
            dots={false}
            nav={false}
            autoplay={false}
            autoplayTimeout={8000}
            smartSpeed={4000}
          >
            {allproducts && allproducts
              .filter((document) => document.topSellingProducts === true)
              .map((product, index) => (
                <div key={index} className="container">
                  <div className="row">
                    <div className="product-details-top-suggestion-item">
                      <div className="d-flex">
                        {user&&user.role==="admin"&&<div className="d-flex justify-content-end" style={{ marginRight: "10px" }} title="Top selling products are being shown here.">
                          <span class="material-symbols-outlined" style={{ fontSize: "15px", color:"var(--black-color)" }}>
                            info
                          </span>
                        </div>}
                        <img
                          className="products-details-top-suggestion-img"
                          src={product.images[0]}
                          alt="suggested product img"
                        />
                      </div>
                      <div className="products-details-top-suggestion-product-info">
                        <p className="products-details-top-suggestion-product-name"><b>{product.name}</b></p>
                        <div className="products-details-top-suggestion-product-meta">
                          {product.rating && (
                            <div className="products-details-top-suggestion-product-rating">
                              <b>{product.rating}</b>&nbsp;
                              <Rating
                                value={parseFloat(product.rating)}
                                precision={0.5}
                                readOnly
                                getLabelText={getLabelText}
                                emptyIcon={
                                  <StarIcon
                                    style={{ opacity: 0.45 }}
                                    fontSize="inherit"
                                  />
                                }
                              />
                            </div>
                          )}
                          {product.discount && (
                            <div className="products-details-top-suggestion-discount">
                              {product.discount} off
                            </div>
                          )}
                          <div className="products-details-top-suggestion-product-price" style={{ color: "var(--text-grey)" }}>
                            {currencyDocument.symbol}{product.price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </OwlCarousel>
        </div>

        <div className="container">
          <div
            className="d-flex justify-content-center product-details-pg-srvc-heading"
          >
            <h5>
              {document.name}
            </h5>
          </div>

          <div className="services-images-slider-div-child">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="pcs_image_area">
                  <div className="bigimage_container">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        maxWidth: "600px",
                      }}
                    >
                      {user && user.role === "admin" && (
                        <div className="gallery-img-delete-icon">
                          <span className="material-symbols-outlined">
                            delete
                          </span>
                        </div>
                      )}
                      <Gallery
                        style={{ background: "red" }}
                        items={images
                          .filter((url) => url)
                          .map((url, index) => ({
                            original: url,
                            thumbnail: url,
                            originalAlt: `Image ${index + 1}`,
                            thumbnailAlt: `Thumbnail ${index + 1}`,
                          }))}
                        slideDuration={1000}
                      />
                    </div>
                    {user && user.role === "admin" && (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="imageInput"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <div className="d-flex">
                          {!isUploading && (
                            <button
                              className="btn_fill me-2"
                              onClick={handleAddMoreImages}
                            >
                              {isConfirmVisible
                                ? "Replace Image"
                                : "Add More Images"}
                            </button>
                          )}

                          {selectedImage && (
                            <button
                              className="btn_fill"
                              onClick={handleConfirmUpload}
                              disabled={!isConfirmVisible || isUploading} // Disable button when uploading
                            >
                              {isUploading ? "Uploading..." : "Confirm"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {selectedImage && (
                      <div style={{ textAlign: "center", marginTop: "15px" }}>
                        <img
                          src={selectedImage}
                          alt="Selected img"
                          style={{ maxWidth: "100px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {user && user.role === "admin" && (
                  <MDBCard
                    className="mt-5"
                    style={{ backgroundColor: "#f0f0f0" }}
                  >
                    <MDBCardBody>
                      <MDBRow className="align-items-center">
                        <div className="d-flex">
                          <div className="col-10 col-md-10">
                            <h6>Top Selling</h6>
                          </div>
                          <div className="col-2 col-md-2">
                            <ReactSwitch
                              id="topSellingSwitch"
                              checked={document.topSellingProducts === true}
                              onChange={() =>
                                handlePopularityToggleChange(
                                  "topSellingProducts",
                                  checkedTopSelling
                                )
                              }
                              onColor="#86d3ff"
                              onHandleColor="#2693e6"
                              handleDiameter={16}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                              height={14}
                              width={32}
                              className="react-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="col-10 col-md-10">
                            <h6>Popular Product</h6>
                          </div>
                          <div className="col-2 col-md-2">
                            <ReactSwitch
                              id="popularProductsSwitch"
                              checked={document.popularProducts === true}
                              onChange={() =>
                                handlePopularityToggleChange(
                                  "popularProducts",
                                  checkedPopular
                                )
                              }
                              onColor="#86d3ff"
                              onHandleColor="#2693e6"
                              handleDiameter={16}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                              height={14}
                              width={32}
                              className="react-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <di2 className="col-10 col-md-10">
                            <h6>New Arrival</h6>
                          </di2>
                          <div className="col-2 col-md-4">
                            <ReactSwitch
                              id="newArrivalsSwitch"
                              checked={document.newArrivals === true}
                              onChange={() =>
                                handlePopularityToggleChange(
                                  "newArrivals",
                                  checkedNewArrivals
                                )
                              }
                              onColor="#86d3ff"
                              onHandleColor="#2693e6"
                              handleDiameter={16}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                              height={14}
                              width={32}
                              className="react-switch"
                            />
                          </div>
                        </div>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                )}
              </div>

              <div className="col-12 col-md-6 col-lg-6 services_details_right_dv">
                <MDBCard className="mb-4">
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Name:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingName ? (
                            <div>
                              <Box
                                sx={{ '& .MuiTextField-root': { width: '100%' } }}
                                noValidate
                                autoComplete="on"
                              >
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="Name"
                                  multiline
                                  maxRows={4}
                                  value={editedName}
                                  onChange={(e) => setEditedName(e.target.value)}
                                />
                              </Box>
                              <div className="d-flex justify-content-end mt-2 gap-2">
                                <button className="btn_border" onClick={() => handleCancelClick("name")}>Cancel</button>
                                <button className="btn_fill" onClick={() => handleSaveClick("name")}>Save</button>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <p>{document.name}</p>
                              {!isEditingName &&
                                user &&
                                user.role === "admin" && (
                                  <FaEdit
                                    style={{
                                      fontSize: 20,
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "var(--click-color)",
                                    }}
                                    onClick={() => handleEditClick("name")}
                                  />
                                )}
                            </div>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <hr />
                    {document.rating && (
                      <>
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Rating:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <MDBRow>
                                <div className="d-flex align-items-center">
                                  <Box
                                    sx={{
                                      width: 200,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Rating

                                      value={parseFloat(document.rating)}
                                      precision={0.5}
                                      readOnly={
                                        user &&
                                        user.role !== "admin"
                                      }
                                      getLabelText={getLabelText}
                                      onChange={(event, newValue) =>
                                        handleRatingChange(newValue)
                                      }
                                      onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                      }}
                                      emptyIcon={
                                        <StarIcon
                                          style={{ opacity: 0.45 }}
                                          fontSize="inherit"
                                        />
                                      }
                                    />
                                  </Box>
                                </div>
                              </MDBRow>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}

                    {/* START PRICE */}
                    {((user && user.role === "admin") ||
                      (document.price && document.price !== "")) && (
                        <>
                          <hr />
                          <MDBRow>
                            <MDBCol sm="3">
                              <MDBCardText>Price:</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="9">
                              <MDBCardText className="text-muted">
                                {isEditingPrice ? (
                                  <div>
                                    <Box
                                      sx={{ '& .MuiTextField-root': { width: '100%' } }}
                                      noValidate
                                      autoComplete="on"
                                    >
                                      <TextField
                                        id="outlined-multiline-flexible"
                                        label="Price"
                                        maxRows={4}
                                        value={editedPrice}
                                        onChange={(e) => setEditedPrice(e.target.value)}
                                      />
                                    </Box>
                                    <div className="d-flex justify-content-end mt-2 gap-2">
                                      <button className="btn_border" onClick={() => handleCancelClick("price")}>Cancel</button>
                                      <button className="btn_fill" onClick={() => handleSaveClick("price")}>Save</button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <p>{currencyDocument.symbol}{document.price}</p>
                                    &nbsp;&nbsp;
                                    <del style={{ color: "red" }}>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            document.discount.toString("html"),
                                        }}
                                      ></p>
                                    </del>
                                    {!isEditingPrice &&
                                      user &&
                                      user.role === "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--click-color)",
                                          }}
                                          onClick={() => handleEditClick("price")}
                                        />
                                      )}
                                  </div>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}

                    {((user && user.role === "admin") ||
                      (document.discount && document.discount !== "")) && (
                        <>
                          <hr />
                          <MDBRow>
                            <MDBCol sm="3">
                              <MDBCardText>Discount:</MDBCardText>
                            </MDBCol>
                            <MDBCol sm="9">
                              <MDBCardText className="text-muted">
                                {isEditingDiscount ? (
                                  <div>
                                    <Box
                                      sx={{ '& .MuiTextField-root': { width: '100%' } }}
                                      noValidate
                                      autoComplete="on"
                                    >
                                      <TextField
                                        id="outlined-multiline-flexible"
                                        label="Discount"
                                        maxRows={4}
                                        value={editedDiscount}
                                        onChange={(e) => setEditedDiscount(e.target.value)}
                                      />
                                    </Box>
                                    <div className="d-flex justify-content-end mt-2 gap-2">
                                      <button className="btn_border" onClick={() => handleCancelClick("discount")}>Cancel</button>
                                      <button className="btn_fill" onClick={() => handleSaveClick("discount")}>Save</button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <p>{document.discount}</p>
                                    {!isEditingName &&
                                      user &&
                                      user.role == "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--click-color)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("discount")
                                          }
                                        />
                                      )}
                                  </div>
                                )}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </>
                      )}
                    <hr />
                    <MDBRow>
                      <MDBCol sm="3">
                        <MDBCardText>Short Description:</MDBCardText>
                      </MDBCol>
                      <MDBCol sm="9">
                        <MDBCardText className="text-muted">
                          {isEditingShortDescription ? (
                            <div>
                              <Box
                                sx={{ '& .MuiTextField-root': { width: '100%' } }}
                                noValidate
                                autoComplete="on"
                              >
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="Short Description"
                                  multiline
                                  maxRows={4}
                                  value={editedShortDescription}
                                  onChange={(e) => setEditedShortDescription(e.target.value)}
                                />
                              </Box>
                              <div className="d-flex justify-content-end mt-2 gap-2">
                                <button className="btn_border" onClick={() => handleCancelClick("shortdescription")}>Cancel</button>
                                <button className="btn_fill" onClick={() => handleSaveClick("shortdescription")}>Save</button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="d-flex align-items-center">
                                <p>{document.short_description}</p>
                                {!isEditingShortDescription &&
                                  user &&
                                  user.role == "admin" && (
                                    <FaEdit
                                      style={{
                                        fontSize: 20,
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        color: "var(--click-color)",
                                      }}
                                      onClick={() =>
                                        handleEditClick("shortdescription")
                                      }
                                    />
                                  )}
                              </div>
                            </>
                          )}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    {(user && user.role === "admin") ||
                      (document && document.external_url !== "") ? (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Demo:</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div>
                                {isEditingProductUrl ? (
                                  <div>
                                    <TextEditor
                                      initialDescription={editedExternalUrl}
                                      docFieldName="external_url"
                                      documentId={productId}
                                      collectionName="products"
                                      onSave={() =>
                                        handleSaveClick("externalurl")
                                      }
                                      onCancel={() =>
                                        handleCancelClick("externalurl")
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          document.external_url.toString(
                                            "html"
                                          ),
                                      }}
                                    ></p>
                                    {!isEditingProductUrl &&
                                      user &&
                                      user.role === "admin" && (
                                        <FaEdit
                                          style={{
                                            fontSize: 20,
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                            color: "var(--click-color)",
                                          }}
                                          onClick={() =>
                                            handleEditClick("producturl")
                                          }
                                        />
                                      )}
                                  </div>
                                )}
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : null}

                    {appTypeDocument &&
                      appTypeDocument.apptype === "lite" ? null : (
                      <>
                        <hr />
                        <MDBRow>
                          <div className="radio-container">
                            {options.map((option, index) => (
                              <label
                                key={index}
                                className={`radio-container ${selectedOptionIndex === index
                                  ? "radio_btn_checked"
                                  : " service_details_radion_btn"
                                  }`}
                              >
                                <div className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="option"
                                    value={option}
                                    onChange={() => handleOptionChange(index)}
                                    className="radio-button"
                                  />
                                  <span
                                    className="material-symbols-outlined"
                                    style={{ fontSize: "21px" }}
                                  >
                                    {selectedOptionIndex === index
                                      ? "done"
                                      : "add"}
                                  </span>
                                  {selctedOption && options}
                                  {user && user.role === "admin" ? (
                                    <span
                                      class="material-symbols-outlined"
                                      onClick={() => handleDeleteOption(index)}
                                    >
                                      close
                                    </span>
                                  ) : null}
                                </div>
                              </label>
                            ))}
                            {isAddOption ? (
                              <div>
                                <input
                                  type="text"
                                  value={AddOption}
                                  onChange={(e) => setAddOption(e.target.value)}
                                />
                                <div className="d-flex">
                                  <button
                                    className="product_edit_save_btn"
                                    onClick={handleSaveAddOption}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="product_edit_save_btn cancel-btn"
                                    onClick={handleCancelAddOption}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <>
                                {user && user.role == "admin" && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={handleAddOptionClick}
                                    style={{ fontSize: "24px" }}
                                  >
                                    add_box
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </MDBRow>
                      </>
                    )}
                    {user && user.role == "admin" && (
                      <>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="3">
                            <MDBCardText>Status: </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="9">
                            <MDBCardText className="text-muted">
                              <div className="d-flex">
                                <div>
                                  <p style={{ marginTop: "2px" }}> Inactive</p>
                                </div>
                                <ReactSwitch
                                  checked={document.status === "active"}
                                  onChange={() =>
                                    handleStatusToggleChange(
                                      document.id,
                                      document.status
                                    )
                                  }
                                  onColor="#149AA2"
                                  offColor="#F05B83"
                                  className="products-status-switch"
                                />
                                <div>
                                  <p style={{ marginTop: "2px" }}>Active</p>
                                </div>
                              </div>
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </>
                    )}
                  </MDBCardBody>
                </MDBCard>
                <div className="d-flex align-items-center namesd">
                  <MDBCol className="d-flex align-items-center">
                    <MDBCardText style={{ cursor: "pointer" }}>
                      <span
                        className="material-symbols-outlined"
                        onClick={shareURL}
                        style={{ fontSize: "23px", padding: "6px 0px" }}
                      >
                        share
                      </span>
                    </MDBCardText>
                    <MDBCardText
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <FaHeart
                        style={{
                          fontSize: 20,
                          cursor: "pointer",
                          color: isHeartClicked[document.id] ? "red" : "grey",
                        }}
                        onClick={() => toggleHeartStatus(document.id)}
                      />
                    </MDBCardText>

                    {user && user.role == "admin" && (
                      <MDBCardText
                        onClick={handleDeleteProduct}
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: "23px", padding: "6px 0px" }}
                        >
                          delete
                        </span>
                      </MDBCardText>
                    )}

                    {appTypeDocument &&
                      appTypeDocument.apptype === "lite" ? null : (
                      <>
                        <MDBCardText
                          style={{
                            display: "flex",
                            padding: "7px 0px 0px 10px",
                          }}
                        >
                          <h4 style={{ fontSize: "15px" }}>
                            | &nbsp; 566+ Bookings
                          </h4>
                        </MDBCardText>
                      </>
                    )}
                  </MDBCol>

                  {appTypeDocument && appTypeDocument.apptype === "lite" ? (
                    <>
                      {user ? (
                        functionTypeDocument &&
                          functionTypeDocument.function == "ecommerce" ? (
                          <>
                            <div className="more-div-big-icon-div-inner pointer">
                              <Link to="/contact">
                                <button
                                  type="submit"
                                  className="booking_services_book_now_btn"
                                >
                                  Check Out
                                </button>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="more-div-big-icon-div-inner pointer">
                              <Link to={`/basicaddbooking/${document.id}`}>
                                <button
                                  type="submit"
                                  className="booking_services_book_now_btn"
                                  disabled={!isOptionSelected}
                                >
                                  Book Now
                                </button>
                              </Link>
                            </div>
                          </>
                        )
                      ) : (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/login">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Login
                            </button>
                          </Link>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {user ? (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to={`/basicaddbooking/${document.id}`}>
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                              disabled={!isOptionSelected}
                            >
                              Check Out
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <div className="more-div-big-icon-div-inner pointer">
                          <Link to="/login">
                            <button
                              type="submit"
                              className="booking_services_book_now_btn"
                            >
                              Check Out
                            </button>
                          </Link>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {appTypeDocument &&
                  appTypeDocument.apptype === "lite" ? null : (
                  <>
                    {!isOptionSelected && (
                      <p style={{ color: "red" }}>
                        Please select an option before checking out
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="container services_details_bottom_details_parent">
            <MDBRow className="justify-content-center align-items-center">
              <MDBCard style={{ borderRadius: "15px" }}>
                <MDBCardBody className="p-4">
                  <MDBTypography tag="h3">
                    <strong style={{ color: "var(--heading-color)" }}>
                      Description
                    </strong>
                  </MDBTypography>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-start align-items-center">
                    <MDBCardText className="mb-0">
                      <MDBCardText className="text-muted">
                        {isEditingDescription ? (
                          <div>
                            <div>
                              <TextEditor
                                initialDescription={editedDescription}
                                documentId={productId}
                                docFieldName="description"
                                collectionName="products"
                                onSave={() => handleSaveClick("description")}
                                onCancel={() =>
                                  handleCancelClick("description")
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: document.description.toString("html"),
                              }}
                            ></p>
                            {!isEditingDescription &&
                              user &&
                              user.role == "admin" && (
                                <FaEdit
                                  style={{
                                    fontSize: 20,
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "var(--click-color)",
                                  }}
                                  onClick={() => handleEditClick("description")}
                                />
                              )}
                          </div>
                        )}
                      </MDBCardText>
                    </MDBCardText>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
            {/* </section> */}
          </div>
        </div>
      </div>
      <CorporateTwoClients
        category="partners"
        pageName="contact"
        section="partners"
        addMoreVideosButton={false}
        addMoreImagesButton={true}
        addMoreTextContentButton={false}
      />
    </>
  );
};

export default ProductDetails;
