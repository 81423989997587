import { useState, useEffect } from 'react';
import { projectFirestore ,timestamp} from '../../firebase config/config';
import { useAuthContext } from './useAuthContext';
import { useFirestore } from './useFirestore';

const useCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const { user } = useAuthContext();
  const { addDocument, updateDocument, deleteDocument } = useFirestore('cart');

  useEffect(() => {
    if (user) {
      const unsubscribe = projectFirestore
        .collection('cart')
        .where('userId', '==', user.uid)
        .onSnapshot((snapshot) => {
          const items = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setCartItems(items);
        });

      return () => unsubscribe();
    }
  }, [user]);

  const addToCart = async (productId) => {
    const existingItem = cartItems.find(item => item.productId === productId);

    if (existingItem) {
      await updateDocument(existingItem.id, {
        quantity: existingItem.quantity + 1,
        updatedAt: timestamp.fromDate(new Date())
      });
    } else {
      await addDocument({
        userId: user.uid,
        productId,
        quantity: 1,
        createdAt: timestamp.fromDate(new Date()),
        updatedAt: timestamp.fromDate(new Date())
      });
    }
  };

  const updateCartItemQuantity = async (cartItemId, newQuantity) => {
    if (newQuantity > 0) {
      await updateDocument(cartItemId, {
        quantity: newQuantity,
        updatedAt: timestamp.fromDate(new Date())
      });
    } else {
      await deleteDocument(cartItemId);
    }
  };

  const removeFromCart = async (cartItemId) => {
    await deleteDocument(cartItemId);
  };

  return {
    cartItems,
    addToCart,
    updateCartItemQuantity,
    removeFromCart
  };
};

export default useCart;