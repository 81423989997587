import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useCollection } from "../firebase files/hooks/useCollection";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useDocument } from "../firebase files/hooks/useDocument";
import OwlCarousel from "react-owl-carousel";
import "aos/dist/aos.css";
import AOS from "aos";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase config/config";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaStar } from "react-icons/fa";
import useCart from "../firebase files/hooks/useCart";
import "./Products.scss";

const ratings = ["⭐", "⭐⭐", "⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐⭐⭐"];

const Products = ({ maxProductsToShow, showInactive }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const queryParams = new URLSearchParams(location.search);
  const subCategoryFromUrl = queryParams.get("subcategory");
  const categoryFromUrl = queryParams.get("category");

  const { user } = useAuthContext();

  // Fetch data from the "products" collection
  const { documents: products, error: productsError } = useCollection(
    "products",
    null,
    ["order"]
  );

  // Fetch products category and sub category
  const { documents: productsCategories, error: productsCategoriesError } =
    useCollection("categories");

  const { document: dbProductDetailsDocuments, error: dbProductDetailsError } =
    useDocument("settings", "serviceDetails");

  // Ensure only the specified number of products are rendered
  const limitedProducts = products && products.slice(0, maxProductsToShow);

  const { updateDocument } = useFirestore("products");
  const {
    addDocument: addCartDocument,
    updateDocument: updateCartDocument,
    deleteDocument: deleteCartDocument,
  } = useFirestore("cart");

  const { document, error } = useDocument("settings", "currency");
  const { document: apptype, error: apptypeerror } = useDocument(
    "settings",
    "AppType"
  );

  // CATEGORIES OF PRODUCTS
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(
    categoryFromUrl || ""
  );
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSearch, setDropdownSearch] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedFilterSubCategory, setSelectedFilterSubCategory] = useState(
    subCategoryFromUrl || ""
  );

  const [productName, setProductName] = useState("");
  const [selectedProductImage, setProductSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  // State for add booking card dropdown
  const [selectedAddCategory, setSelectedAddCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedAddSubCategory, setSelectedAddSubCategory] = useState("");
  const [isCardVisible, setIsCardVisible] = useState(false);

  const [query, setQuery] = useState("");

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductImageChange = (event) => {
    const file = event.target.files[0];
    setProductSelectedImage(file);
  };

  // product categories
  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedAddCategory(category);
    const selectedCategoryDoc = productsCategories.find(
      (cat) => cat.category === category
    );
    setSubCategories(
      selectedCategoryDoc ? selectedCategoryDoc.subCategory : []
    );
    setSelectedAddSubCategory(""); // Reset subcategory
  };

  const handleSubCategoryChange = (event) => {
    setSelectedAddSubCategory(event.target.value);
  };

  const handleAddProduct = async () => {
    if (!productName || !selectedAddCategory || !selectedAddSubCategory) {
      setErrorText("Please fill in all fields.");
      return;
    }

    setIsLoading(true);
    let simulatedProgress = 0;

    try {
      const newProductRef = await projectFirestore.collection("products").add({
        name: productName,
        price: "",
        discount: "",
        rating: "⭐⭐⭐⭐",
        order: 0,
        external_url: "",
        ratings: ratings,
        short_description: "Add Short Description",
        description: "Add Full Description About Product",
        status: "inactive",
        createdAt: timestamp.fromDate(new Date()),
        images: [],
        category: selectedAddCategory,
        subCategory: selectedAddSubCategory,
        newArrivals: false,
        popularProducts: false,
        topSellingProducts: false,
        city: "",
      });

      if (selectedProductImage) {
        const newProductId = newProductRef.id;
        const storageRef = projectStorage.ref(`product_img/${newProductId}`);
        const imageRef = storageRef.child(selectedProductImage.name);

        const uploadTask = imageRef.put(selectedProductImage);

        const simulateProgressInterval = setInterval(() => {
          if (simulatedProgress < 95) {
            simulatedProgress += 5; // Increment value by 5%
            setUploadProgress((prevProgress) =>
              Math.max(prevProgress, simulatedProgress)
            );
          }
        }, 500); // Interval duration of 0.5 seconds

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prevProgress) =>
              Math.max(prevProgress, progress)
            );
          },
          (error) => {
            console.error("Error uploading image:", error);
            clearInterval(simulateProgressInterval);
            setIsLoading(false);
          },
          async () => {
            clearInterval(simulateProgressInterval);
            const imageUrl = await uploadTask.snapshot.ref.getDownloadURL();
            await newProductRef.update({ images: [imageUrl] });

            setProductName("");
            setProductSelectedImage(null);
            setUploadProgress(100);
            setTimeout(() => {
              setUploadProgress(0);
              setIsLoading(false);
            }, 500); // Delay to show 100% completion
          }
        );
      } else {
        setProductName("");
        setSelectedAddCategory("");
        setSelectedAddSubCategory("");
        setProductSelectedImage(null);
        setUploadProgress(0);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setIsLoading(false);
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("");
    setSelectedAddCategory("");
    setSelectedAddSubCategory("");
    setProductSelectedImage(null);
    setErrorText("");
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  // START CODE FOR DRAG products
  const [isDragging, setIsDragging] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = async (droppedIndex) => {
    try {
      if (draggedIndex !== droppedIndex) {
        const draggedService = products[draggedIndex];
        const droppedService = products[droppedIndex];
        const updatedServices = [...products];
        updatedServices[droppedIndex] = draggedService;
        updatedServices[draggedIndex] = droppedService;
        await updateDocument(draggedService.id, { order: droppedIndex });
        await updateDocument(droppedService.id, { order: draggedIndex });
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setDraggedIndex(null);
    }
  };

  const handleDrag = (e) => {
    const { clientY } = e;
    const viewportHeight = window.innerHeight;
    const threshold = 50; // Adjust this threshold as needed

    // Calculate distance from top and bottom edges of the viewport
    const distanceToTop = clientY;
    const distanceToBottom = viewportHeight - clientY;

    // Check if the item is close to the top edge
    if (distanceToTop < threshold) {
      // Scroll up
      window.scrollBy(0, -10); // Adjust scroll speed as needed
    } else if (distanceToBottom < threshold) {
      // Check if the item is close to the bottom edge
      // Scroll down
      window.scrollBy(0, 10); // Adjust scroll speed as needed
    }
  };
  // END CODE FOR DRAG SERVICES

  // SEARCH AND OTHER FILTERS CODE START

  const handleSearchInputChange = (event) => {
    const searchTerm = event.target.value;
    setQuery(searchTerm);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      setDropdownSearch(""); // Clear dropdown search input when opening
      setFilteredCategories(productsCategories); // Reset filtered categories
    }
  };

  const handleFilterDropdownSearch = (event) => {
    const searchValue = event.target.value;
    setDropdownSearch(searchValue);

    // Filter categories based on search term
    const filtered = productsCategories.filter((categoryDoc) =>
      categoryDoc.category.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const handleFilterDropdownItemClick = (categoryValue) => {
    setSelectedFilterCategory(categoryValue);
    setSelectedFilterSubCategory(""); // Reset subcategory when category changes

    const selectedCategoryDoc = productsCategories.find(
      (cat) => cat.category === categoryValue
    );

    const subCategories = selectedCategoryDoc?.subCategory || [];
    setSelectedSubCategories(subCategories);

    setDropdownOpen(false);
  };

  // Add useEffect to set subcategories based on URL or selected category
  const filterSubCategoriesWithProducts = (categories, products) => {
    return categories
      .map((category) => ({
        ...category,
        subCategory: category.subCategory.filter((subCategory) =>
          products.some(
            (product) => product.subCategory === subCategory.subCategoryName
          )
        ),
      }))
      .filter((category) => category.subCategory.length > 0);
  };

  // Update this part in your useEffect to set subcategories based on products
  useEffect(() => {
    if (selectedFilterCategory && productsCategories && products) {
      const selectedCategoryDoc = productsCategories.find(
        (cat) => cat.category === selectedFilterCategory
      );

      const filteredCategoriesWithProducts = filterSubCategoriesWithProducts(
        [selectedCategoryDoc],
        products
      );
      const subCategories =
        filteredCategoriesWithProducts[0]?.subCategory || [];
      setSelectedSubCategories(subCategories);
    }
  }, [selectedFilterCategory, productsCategories, products]);

  // Handle click on subcategory
  const handleSubCategoryClick = (subCategory) => {
    setSelectedFilterSubCategory(subCategory);
  };

  useEffect(() => {
    setSelectedFilterCategory(categoryFromUrl || "");
    setSelectedFilterSubCategory(subCategoryFromUrl || "");
  }, [categoryFromUrl, subCategoryFromUrl]);

  // Filter products based on subcategory from URL, selected category, and search query
  const filteredProducts =
    products &&
    products
      .filter((product) => {
        if (
          selectedFilterCategory &&
          product.category !== selectedFilterCategory
        ) {
          return false;
        }
        if (
          selectedFilterSubCategory &&
          product.subCategory !== selectedFilterSubCategory
        ) {
          return false;
        }
        if (
          query &&
          !(
            product.name.toLowerCase().includes(query.toLowerCase()) ||
            product.short_description
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            product.subCategory.toLowerCase().includes(query.toLowerCase()) ||
            product.category.toLowerCase().includes(query.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      })
      .slice(0, maxProductsToShow);

  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };
  // SEARCH AND OTHER FILTERS CODE END

  const handleProductClick = (subcategory) => {
    // Get the existing subcategories array from localStorage or initialize an empty array if it doesn't exist
    let subcategories =
      JSON.parse(localStorage.getItem("clickedSubcategories")) || [];

    // Check if the subcategory already exists in the array
    const index = subcategories.indexOf(subcategory);

    // If the subcategory exists, remove it from its current position
    if (index !== -1) {
      subcategories.splice(index, 1);
    }

    // Add the new subcategory to the end of the array
    subcategories.push(subcategory);

    // If the array length exceeds 2, remove the first element
    if (subcategories.length > 2) {
      subcategories.shift();
    }

    // Update localStorage with the new subcategories array
    localStorage.setItem("clickedSubcategories", JSON.stringify(subcategories));

    console.log(`Subcategory: ${subcategory}`);
    console.log(`Recent subcategories: ${subcategories}`);
  };

  // modal functionality

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // ADD TO CART Function Start

  const { cartItems, addToCart, updateCartItemQuantity, removeFromCart } = useCart();


  const isProductInCart = (productId) => {
    return cartItems.some(item => item.productId === productId);
  };

  const findCartItem = (productId) => {
    return cartItems.find(item => item.productId === productId);
  };

  const handleAddToCart = (productId) => {
    addToCart(productId);
  };

  const handleIncrementQuantity = (cartItem) => {
    updateCartItemQuantity(cartItem.id, cartItem.quantity + 1);
  };

  const handleDecrementQuantity = (cartItem) => {
    if (cartItem.quantity > 1) {
      updateCartItemQuantity(cartItem.id, cartItem.quantity - 1);
    } else {
      removeFromCart(cartItem.id);
    }
  };

  // const [cartItems, setCartItems] = useState([]);
  // console.log(cartItems);

  // const isProductInCart = (productId) => {
  //   if (cartItems) {
  //     const product = cartItems.find((item, index) => {
  //       return item.productId === productId;
  //     });
  //     if (product) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // const findProductQuantity = (productId) => {
  //   const product = cartItems.find((item, index) => {
  //     return item.productId === productId;
  //   });
  //   return Number(product.quantity);
  // };

  // const incrementQuantity = (productId) => {
  //   setCartItems(
  //     cartItems.map((item, index) => {
  //       if (item.productId === productId) {
  //         return {...item,quantity:item.quantity+1}
  //       }
  //       return item;
  //     })
  //   );
  // };

  // const decrementQuantity = (productId) => {
  //   let productQuantity = findProductQuantity(productId);
  //   if (productQuantity > 1) {
  //     setCartItems(
  //       cartItems.map((item, index) => {
  //         if (item.productId === productId) {
  //           return {...item,quantity:item.quantity-1}
  //         }
  //         return item;
  //       })
  //     );
  //   } else {
  //     setCartItems(
  //       cartItems.filter((item, index) => {
  //         return item.productId !== productId;
  //       })
  //     );
  //   }
  // };

  // const addProductToCart = async (productId) => {
  //     setCartItems([...cartItems, { productId, quantity: 1 }]);



  //     try{
  //       const newCartItem = {productId,quantity:1,updatedAt:timestamp.fromDate(new Date())}
  //        const response= await addCartDocument(newCartItem)
         
  //     }catch(err){
  //       console.log(err)
  //     }
  // };

  return (
    <div>
      <section className="doctors-area">
        {/* SEARCH AND OTHER FILTERS START  */}
        <div className="ProductsFilterAndSearch">
          <div className="ProductsFilterAndSearch-filter-card">
            <div className="ProductsFilterAndSearch-search-bar">
              <input
                type="text"
                value={query}
                onChange={handleSearchInputChange}
                placeholder="Search products..."
              />
            </div>
            <div className="ProductsFilterAndSearch-category-dropdown">
              <div className="dropdown-container">
                <div
                  className={`ProductsFilterAndSearch-dropdown-header ${
                    dropdownOpen ? "open" : ""
                  }`}
                  onClick={toggleDropdown}
                >
                  <span className="ProductsFilterAndSearch-dropdown-header-text">
                    {selectedFilterCategory || "Select Category"}
                  </span>
                </div>
                {dropdownOpen && (
                  <div className="ProductsFilterAndSearch-dropdown-content">
                    <input
                      type="text"
                      value={dropdownSearch}
                      onChange={handleFilterDropdownSearch}
                      placeholder="Search categories..."
                      className="ProductsFilterAndSearch-dropdown-search-input"
                    />
                    <ul className="ProductsFilterAndSearch-dropdown-list">
                      {filteredCategories.map((categoryDoc) => (
                        <li
                          key={categoryDoc.id}
                          className="ProductsFilterAndSearch-dropdown-item"
                          onClick={() =>
                            handleFilterDropdownItemClick(categoryDoc.category)
                          }
                        >
                          {categoryDoc.category}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* START FILTER FOR MOBILE */}
            <span
              className="material-symbols-outlined"
              onClick={toggleCardVisibility}
              style={{ marginTop: "7px", fontSize: "25px" }}
            >
              tune
            </span>
          </div>

          {/* show subcategories name below */}
          {selectedSubCategories.length > 0 && (
            <div className="ProductsFilterAndSearch-subcategory-container">
              <ul className="ProductsFilterAndSearch-subcategory-list">
                {selectedSubCategories.map((subCategory, index) => (
                  <li
                    key={index}
                    className={`ProductsFilterAndSearch-subcategory-item ${
                      subCategory.subCategoryName === selectedFilterSubCategory
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSubCategoryClick(subCategory.subCategoryName)
                    }
                  >
                    <img
                      src={subCategory.imgUrl}
                      style={{ width: "18px" }}
                      alt={subCategory.subCategoryName}
                    />
                    &nbsp;
                    <p>{subCategory.subCategoryName}</p>
                    <span className="ProductsFilterAndSearch-arrow-icon">
                      {subCategory.subCategoryName ===
                      selectedFilterSubCategory ? (
                        <span className="material-symbols-outlined">
                          arrow_downward
                        </span>
                      ) : (
                        <span className="material-symbols-outlined">north</span>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {/* SEARCH AND OTHER FILTERS END  */}

        <div className="container">
          {/* ABOVE ACTIVE PRODUCTS START*/}
          <br />
          <div>
            {user && user.role === "admin" && (
              <div className="product-page-heading-title">
                <h2
                  style={{
                    fontSize: "20px",
                    marginLeft: "0px",
                    color: "var(--heading-color)",
                  }}
                >
                  Active Products
                </h2>
              </div>
            )}
            <br />
            <div className="row g-5">
              {filteredProducts &&
                filteredProducts.map((doc, index) =>
                  (user &&
                    user.role === "admin" &&
                    doc &&
                    doc.status === "active") ||
                  (doc && doc.status === "active") ? (
                    <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                      <div className="corporateTwo-card-2">
                        <div className="card-2-image-container">
                          <OwlCarousel
                            key={doc.id} // Adding key to force re-render
                            nav={false}
                            dots={false}
                            loop
                            autoplay
                            autoplayTimeout={3000}
                            items={1}
                          >
                            {doc.images &&
                              doc.images.map((imageUrl, index) => (
                                <div key={index}>
                                  <img
                                    src={imageUrl}
                                    alt={`Product Image ${index}`}
                                  />
                                </div>
                              ))}
                          </OwlCarousel>

                          {user && user.role === "admin" ? (
                            <div
                              className={`${
                                doc.status && doc.status == "inactive"
                                  ? "card-2-inactive-status"
                                  : "card-2-active-status"
                              }`}
                            >
                              <div
                                className={`${
                                  doc.status && doc.status == "inactive"
                                    ? "card-2-inactive-flag"
                                    : "card-2-active-flag"
                                }`}
                              ></div>
                              <span>
                                {doc.status && doc.status == "inactive"
                                  ? "InActive"
                                  : "Active"}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="corporateTwo-card-wishlist">
                            <span class="material-symbols-outlined">
                              favorite
                            </span>
                          </div>

                          <div className="card-2-rating-info">
                            <span>4.5</span>
                            <FaStar color="#fca503" size={20} />
                          </div>
                        </div>

                        <div className="card-2-info-container">
                          <div className="corporatetwo-card-2-title-info">
                            <h4
                              className="corporateTwo-card-2-title"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString(),
                              }}
                            ></h4>
                            <p className="corporateTwo-card-2-short-desc">
                              This is a short description of the main and this
                              is this is that product
                            </p>
                          </div>

                          <div className="card-2-price-info">
                            <div className="card-2-selling-price">
                              <h3>{document && document.symbol} 1,52,500</h3>
                            </div>

                            <div className="card-2-discount-info">
                              <p>
                                <del>
                                  {document && document.symbol} 2,25,599
                                </del>
                              </p>
                              <span>15% off</span>
                            </div>
                          </div>

                          <div className="card-2-important-info">
                            {isProductInCart(doc.id) ? (
                              <div className="corporateTwo-card-2-quantity">
                                <div>
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleDecrementQuantity(findCartItem(doc.id))}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={findCartItem(doc.id).quantity}
                                    readOnly
                                  />
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="card-2-cart-btn"
                                onClick={() => handleAddToCart(doc.id)}
                              >
                                <span>Cart</span>
                                <span class="material-symbols-outlined">
                                  shopping_cart
                                </span>
                              </button>
                            )}

                            <div className="card-2-cart-btn-container">
                              <Link
                                to={
                                  dbProductDetailsDocuments &&
                                  dbProductDetailsDocuments.status === true &&
                                  `/product-details/${doc.id}`
                                }
                              >
                                <button className="card-2-detail-btn">
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
            </div>
          </div>
          {/* ABOVE ACTIVE PRODUCTS END*/}

          {/* POPULAR PRODUCTS START*/}
          {showInactive || (
            <>
              {filteredProducts &&
                filteredProducts.some(
                  (doc) => doc.popularProducts === true
                ) && (
                  <div>
                    <br />
                    <hr />
                    <div className="product-page-heading-title">
                      <h2
                        style={{
                          fontSize: "20px",
                          marginLeft: "0px",
                          color: "var(--heading-color)",
                        }}
                      >
                        Popular Products
                      </h2>
                    </div>

                    <br />

                    <div className="row g-5">
                      {filteredProducts.map((doc, index) =>
                        (user &&
                          user.role === "admin" &&
                          doc &&
                          doc.popularProducts === true) ||
                        (doc && doc.popularProducts === true) ? (
                          <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                            <div className="corporateTwo-card-2">
                              <div className="card-2-image-container">
                                <OwlCarousel
                                  key={doc.id} // Adding key to force re-render
                                  nav={false}
                                  dots={false}
                                  loop
                                  autoplay
                                  autoplayTimeout={3000}
                                  items={1}
                                >
                                  {doc.images &&
                                    doc.images.map((imageUrl, index) => (
                                      <div key={index}>
                                        <img
                                          src={imageUrl}
                                          alt={`Product Image ${index}`}
                                        />
                                      </div>
                                    ))}
                                </OwlCarousel>

                                {user && user.role === "admin" ? (
                                  <div
                                    className={`${
                                      doc.status && doc.status == "inactive"
                                        ? "card-2-inactive-status"
                                        : "card-2-active-status"
                                    }`}
                                  >
                                    <div
                                      className={`${
                                        doc.status && doc.status == "inactive"
                                          ? "card-2-inactive-flag"
                                          : "card-2-active-flag"
                                      }`}
                                    ></div>
                                    <span>
                                      {doc.status && doc.status == "inactive"
                                        ? "InActive"
                                        : "Active"}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="corporateTwo-card-wishlist">
                                  <span class="material-symbols-outlined">
                                    favorite
                                  </span>
                                </div>

                                <div className="card-2-rating-info">
                                  <span>4.5</span>
                                  <FaStar color="#fca503" size={20} />
                                </div>
                              </div>
                              

                              <div className="card-2-info-container">
                          <div className="corporatetwo-card-2-title-info">
                            <h4
                              className="corporateTwo-card-2-title"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString(),
                              }}
                            ></h4>
                            <p className="corporateTwo-card-2-short-desc">
                              This is a short description of the main and this
                              is this is that product
                            </p>
                          </div>

                          <div className="card-2-price-info">
                            <div className="card-2-selling-price">
                              <h3>{document && document.symbol} 1,52,500</h3>
                            </div>

                            <div className="card-2-discount-info">
                              <p>
                                <del>
                                  {document && document.symbol} 2,25,599
                                </del>
                              </p>
                              <span>15% off</span>
                            </div>
                          </div>

                          <div className="card-2-important-info">
                            {isProductInCart(doc.id) ? (
                              <div className="corporateTwo-card-2-quantity">
                                <div>
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleDecrementQuantity(findCartItem(doc.id))}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={findCartItem(doc.id).quantity}
                                    readOnly
                                  />
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="card-2-cart-btn"
                                onClick={() => handleAddToCart(doc.id)}
                              >
                                <span>Cart</span>
                                <span class="material-symbols-outlined">
                                  shopping_cart
                                </span>
                              </button>
                            )}

                            <div className="card-2-cart-btn-container">
                              <Link
                                to={
                                  dbProductDetailsDocuments &&
                                  dbProductDetailsDocuments.status === true &&
                                  `/product-details/${doc.id}`
                                }
                              >
                                <button className="card-2-detail-btn">
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                )}
            </>
          )}
          {/* POPULAR PRODUCTS END*/}

          {/* TOP SELLING PRODUCTS START*/}
          {showInactive || (
            <>
              {filteredProducts &&
                filteredProducts.some(
                  (doc) => doc.topSellingProducts === true
                ) && (
                  <div>
                    <br />
                    <hr />
                    <div className="product-page-heading-title">
                      <h2
                        style={{
                          fontSize: "20px",
                          marginLeft: "0px",
                          color: "var(--heading-color)",
                        }}
                      >
                        Top Selling Products
                      </h2>
                    </div>

                    <br />

                    <div className="row g-5">
                      {filteredProducts &&
                        filteredProducts.map((doc, index) =>
                          (user &&
                            user.role === "admin" &&
                            doc &&
                            doc.topSellingProducts === true) ||
                          (doc && doc.topSellingProducts === true) ? (
                            // <div
                            //   className="col-6 col-sm-6 col-lg-3 products-cards-parent-div"
                            //   key={index}
                            // >
                            //   <div className="small_margin_div"></div>
                            //   <Link
                            //     to={
                            //       dbProductDetailsDocuments &&
                            //       dbProductDetailsDocuments.status === true &&
                            //       `/product-details/${doc.id}`
                            //     }
                            //   >
                            //     <div
                            //       className={`doctor-item ss ${
                            //         isDragging ? "dragging" : ""
                            //       }`}
                            //       data-aos="flip-right"
                            //     >
                            //       <div
                            //         className={`doctor-item ss ${
                            //           draggedIndex === index ? "dragging" : ""
                            //         }`}
                            //         draggable={
                            //           user && user.role === "admin" && true
                            //         }
                            //         onDragStart={() => handleDragStart(index)}
                            //         onDrag={(e) => handleDrag(e)}
                            //         onDragOver={(e) => e.preventDefault()}
                            //         onDrop={() => handleDrop(index)}
                            //       >
                            //         <div className="doctor-top product-thumb-img">
                            //           <OwlCarousel
                            //             key={doc.id} // Adding key to force re-render
                            //             nav={false}
                            //             dots={false}
                            //             loop
                            //             autoplay
                            //             autoplayTimeout={3000}
                            //             items={1}
                            //           >
                            //             {doc.images &&
                            //               doc.images.map((imageUrl, index) => (
                            //                 <div key={index}>
                            //                   <img
                            //                     src={imageUrl}
                            //                     alt={`Product Image ${index}`}
                            //                   />
                            //                 </div>
                            //               ))}
                            //           </OwlCarousel>
                            //           <div className="d-flex space-between">
                            //             <div>
                            //               {user &&
                            //               user.role === "admin" &&
                            //               user.status === "active" ? (
                            //                 <>
                            //                   <div className="status-button-container">
                            //                     <button
                            //                       className={`booking_services_book_now_btn ${doc.status}`}
                            //                       style={{
                            //                         backgroundColor:
                            //                           doc.status === "active"
                            //                             ? "var(--click-color)"
                            //                             : "var(--pink)",
                            //                       }}
                            //                     >
                            //                       {doc.status}
                            //                     </button>
                            //                   </div>
                            //                   <div className="drag-button-container">
                            //                     <span
                            //                       class="material-symbols-outlined"
                            //                       style={{
                            //                         color: "var(--click-color)",
                            //                         fontSize: "30px",
                            //                         cursor: "grab",
                            //                       }}
                            //                     >
                            //                       apps
                            //                     </span>
                            //                     <span className="tooltip">
                            //                       Drag and swap cards position
                            //                     </span>
                            //                   </div>
                            //                 </>
                            //               ) : null}
                            //             </div>
                            //           </div>
                            //         </div>
                            //         {apptype &&
                            //         apptype.apptype === "lite" ? null : (
                            //           <>
                            //             <div className="booking_services_price_location">
                            //               {doc.price && doc.price != "" ? (
                            //                 <div className="d-flex align-items-center justify-content-center flex-column">
                            //                   <div className="d-flex align-items-center">
                            //                     <h6
                            //                       style={{
                            //                         fontWeight: "700",
                            //                       }}
                            //                     >
                            //                       {document && document.symbol}
                            //                     </h6>
                            //                     &nbsp;
                            //                     <p
                            //                       dangerouslySetInnerHTML={{
                            //                         __html:
                            //                           doc.price.toString(
                            //                             "html"
                            //                           ),
                            //                       }}
                            //                     ></p>
                            //                     &nbsp;
                            //                     <strike
                            //                       style={{
                            //                         color: "var(--red-color)",
                            //                       }}
                            //                     >
                            //                       <p
                            //                         dangerouslySetInnerHTML={{
                            //                           __html:
                            //                             doc.discount.toString(
                            //                               "html"
                            //                             ),
                            //                         }}
                            //                       ></p>
                            //                     </strike>
                            //                   </div>
                            //                 </div>
                            //               ) : (
                            //                 <h5>{doc.rating}</h5>
                            //               )}
                            //             </div>
                            //           </>
                            //         )}
                            //         <div className="booking_services_name_haedings_parent">
                            //           <h1
                            //             className="doctors-name-h5"
                            //             dangerouslySetInnerHTML={{
                            //               __html: doc.name.toString("html"),
                            //             }}
                            //           ></h1>
                            //           <p
                            //             className="product-short-detail"
                            //             dangerouslySetInnerHTML={{
                            //               __html:
                            //                 doc.short_description.toString(
                            //                   "html"
                            //                 ),
                            //             }}
                            //           ></p>

                            //           {dbProductDetailsDocuments &&
                            //             dbProductDetailsDocuments.status ==
                            //               true && (
                            //               <div className="booking_services_two_buttons">
                            //                 <div className="d-flex">
                            //                   {doc.price && doc.price != "" && (
                            //                     <div className="d-flex align-items-center">
                            //                       {document && document.symbol}
                            //                       &nbsp;
                            //                       <p
                            //                         dangerouslySetInnerHTML={{
                            //                           __html:
                            //                             doc.price.toString(
                            //                               "html"
                            //                             ),
                            //                         }}
                            //                       ></p>
                            //                     </div>
                            //                   )}
                            //                 </div>

                            //                 <div className="d-flex">
                            //                   Details
                            //                   <span className="material-symbols-outlined booking_services_see_all_btn">
                            //                     chevron_right
                            //                   </span>
                            //                 </div>
                            //               </div>
                            //             )}
                            //         </div>
                            //       </div>
                            //     </div>
                            //   </Link>
                            // </div>
                            <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                              <div className="corporateTwo-card-2">
                                <div className="card-2-image-container">
                                  <OwlCarousel
                                    key={doc.id} // Adding key to force re-render
                                    nav={false}
                                    dots={false}
                                    loop
                                    autoplay
                                    autoplayTimeout={3000}
                                    items={1}
                                  >
                                    {doc.images &&
                                      doc.images.map((imageUrl, index) => (
                                        <div key={index}>
                                          <img
                                            src={imageUrl}
                                            alt={`Product Image ${index}`}
                                          />
                                        </div>
                                      ))}
                                  </OwlCarousel>

                                  {user && user.role === "admin" ? (
                                    <div
                                      className={`${
                                        doc.status && doc.status == "inactive"
                                          ? "card-2-inactive-status"
                                          : "card-2-active-status"
                                      }`}
                                    >
                                      <div
                                        className={`${
                                          doc.status && doc.status == "inactive"
                                            ? "card-2-inactive-flag"
                                            : "card-2-active-flag"
                                        }`}
                                      ></div>
                                      <span>
                                        {doc.status && doc.status == "inactive"
                                          ? "InActive"
                                          : "Active"}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="corporateTwo-card-wishlist">
                                    <span class="material-symbols-outlined">
                                      favorite
                                    </span>
                                  </div>

                                  <div className="card-2-rating-info">
                                    <span>4.5</span>
                                    <FaStar color="#fca503" size={20} />
                                  </div>
                                </div>

                                <div className="card-2-info-container">
                          <div className="corporatetwo-card-2-title-info">
                            <h4
                              className="corporateTwo-card-2-title"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString(),
                              }}
                            ></h4>
                            <p className="corporateTwo-card-2-short-desc">
                              This is a short description of the main and this
                              is this is that product
                            </p>
                          </div>

                          <div className="card-2-price-info">
                            <div className="card-2-selling-price">
                              <h3>{document && document.symbol} 1,52,500</h3>
                            </div>

                            <div className="card-2-discount-info">
                              <p>
                                <del>
                                  {document && document.symbol} 2,25,599
                                </del>
                              </p>
                              <span>15% off</span>
                            </div>
                          </div>

                          <div className="card-2-important-info">
                            {isProductInCart(doc.id) ? (
                              <div className="corporateTwo-card-2-quantity">
                                <div>
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleDecrementQuantity(findCartItem(doc.id))}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={findCartItem(doc.id).quantity}
                                    readOnly
                                  />
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="card-2-cart-btn"
                                onClick={() => handleAddToCart(doc.id)}
                              >
                                <span>Cart</span>
                                <span class="material-symbols-outlined">
                                  shopping_cart
                                </span>
                              </button>
                            )}

                            <div className="card-2-cart-btn-container">
                              <Link
                                to={
                                  dbProductDetailsDocuments &&
                                  dbProductDetailsDocuments.status === true &&
                                  `/product-details/${doc.id}`
                                }
                              >
                                <button className="card-2-detail-btn">
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                  </div>
                )}
            </>
          )}
          {/* TOP SELLING PRODUCTS END*/}

          {/* NEW ARRIVALS PRODUCTS START*/}
          {showInactive || (
            <>
              {filteredProducts &&
                filteredProducts.some((doc) => doc.newArrivals === true) && (
                  <div>
                    <br />
                    <hr />
                    <div className="product-page-heading-title">
                      <h2
                        style={{
                          fontSize: "20px",
                          marginLeft: "0px",
                          color: "var(--heading-color)",
                        }}
                      >
                        New Arrivals Products
                      </h2>
                    </div>

                    <br />

                    <div className="row g-5">
                      {filteredProducts &&
                        filteredProducts.map((doc, index) =>
                          (user &&
                            user.role === "admin" &&
                            doc &&
                            doc.newArrivals === true) ||
                          (doc && doc.newArrivals === true) ? (
                            // <div
                            //   className="col-6 col-sm-6 col-lg-3 products-cards-parent-div"
                            //   key={index}
                            // >
                            //   <div className="small_margin_div"></div>
                            //   <Link
                            //     to={
                            //       dbProductDetailsDocuments &&
                            //       dbProductDetailsDocuments.status === true &&
                            //       `/product-details/${doc.id}`
                            //     }
                            //   >
                            //     <div
                            //       className={`doctor-item ss ${
                            //         isDragging ? "dragging" : ""
                            //       }`}
                            //       data-aos="flip-right"
                            //     >
                            //       <div
                            //         className={`doctor-item ss ${
                            //           draggedIndex === index ? "dragging" : ""
                            //         }`}
                            //         draggable={
                            //           user && user.role === "admin" && true
                            //         }
                            //         onDragStart={() => handleDragStart(index)}
                            //         onDrag={(e) => handleDrag(e)}
                            //         onDragOver={(e) => e.preventDefault()}
                            //         onDrop={() => handleDrop(index)}
                            //       >
                            //         <div className="doctor-top product-thumb-img">
                            //           <OwlCarousel
                            //             key={doc.id} // Adding key to force re-render
                            //             nav={false}
                            //             dots={false}
                            //             loop
                            //             autoplay
                            //             autoplayTimeout={3000}
                            //             items={1}
                            //           >
                            //             {doc.images &&
                            //               doc.images.map((imageUrl, index) => (
                            //                 <div key={index}>
                            //                   <img
                            //                     src={imageUrl}
                            //                     alt={`Product Image ${index}`}
                            //                   />
                            //                 </div>
                            //               ))}
                            //           </OwlCarousel>
                            //           <div className="d-flex space-between">
                            //             <div>
                            //               {user &&
                            //               user.role === "admin" &&
                            //               user.status === "active" ? (
                            //                 <>
                            //                   <div className="status-button-container">
                            //                     <button
                            //                       className={`booking_services_book_now_btn ${doc.status}`}
                            //                       style={{
                            //                         backgroundColor:
                            //                           doc.status === "active"
                            //                             ? "var(--click-color)"
                            //                             : "var(--pink)",
                            //                       }}
                            //                     >
                            //                       {doc.status}
                            //                     </button>
                            //                   </div>
                            //                   <div className="drag-button-container">
                            //                     <span
                            //                       class="material-symbols-outlined"
                            //                       style={{
                            //                         color: "var(--click-color)",
                            //                         fontSize: "30px",
                            //                         cursor: "grab",
                            //                       }}
                            //                     >
                            //                       apps
                            //                     </span>
                            //                     <span className="tooltip">
                            //                       Drag and swap cards position
                            //                     </span>
                            //                   </div>
                            //                 </>
                            //               ) : null}
                            //             </div>
                            //           </div>
                            //         </div>
                            //         {apptype &&
                            //         apptype.apptype === "lite" ? null : (
                            //           <>
                            //             <div className="booking_services_price_location">
                            //               {doc.price && doc.price != "" ? (
                            //                 <div className="d-flex align-items-center justify-content-center flex-column">
                            //                   <div className="d-flex align-items-center">
                            //                     <h6
                            //                       style={{
                            //                         fontWeight: "700",
                            //                       }}
                            //                     >
                            //                       {document && document.symbol}
                            //                     </h6>
                            //                     &nbsp;
                            //                     <p
                            //                       dangerouslySetInnerHTML={{
                            //                         __html:
                            //                           doc.price.toString(
                            //                             "html"
                            //                           ),
                            //                       }}
                            //                     ></p>
                            //                     &nbsp;
                            //                     <strike
                            //                       style={{
                            //                         color: "var(--red-color)",
                            //                       }}
                            //                     >
                            //                       <p
                            //                         dangerouslySetInnerHTML={{
                            //                           __html:
                            //                             doc.discount.toString(
                            //                               "html"
                            //                             ),
                            //                         }}
                            //                       ></p>
                            //                     </strike>
                            //                   </div>
                            //                 </div>
                            //               ) : (
                            //                 <h5>{doc.rating}</h5>
                            //               )}
                            //             </div>
                            //           </>
                            //         )}
                            //         <div className="booking_services_name_haedings_parent">
                            //           <h1
                            //             className="doctors-name-h5"
                            //             dangerouslySetInnerHTML={{
                            //               __html: doc.name.toString("html"),
                            //             }}
                            //           ></h1>
                            //           <p
                            //             className="product-short-detail"
                            //             dangerouslySetInnerHTML={{
                            //               __html:
                            //                 doc.short_description.toString(
                            //                   "html"
                            //                 ),
                            //             }}
                            //           ></p>

                            //           {dbProductDetailsDocuments &&
                            //             dbProductDetailsDocuments.status ==
                            //               true && (
                            //               <div className="booking_services_two_buttons">
                            //                 <div className="d-flex">
                            //                   {doc.price && doc.price != "" && (
                            //                     <div className="d-flex align-items-center">
                            //                       {document && document.symbol}
                            //                       &nbsp;
                            //                       <p
                            //                         dangerouslySetInnerHTML={{
                            //                           __html:
                            //                             doc.price.toString(
                            //                               "html"
                            //                             ),
                            //                         }}
                            //                       ></p>
                            //                     </div>
                            //                   )}
                            //                 </div>

                            //                 <div className="d-flex">
                            //                   Details
                            //                   <span className="material-symbols-outlined booking_services_see_all_btn">
                            //                     chevron_right
                            //                   </span>
                            //                 </div>
                            //               </div>
                            //             )}
                            //         </div>
                            //       </div>
                            //     </div>
                            //   </Link>
                            // </div>
                            <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                              <div className="corporateTwo-card-2">
                                <div className="card-2-image-container">
                                  <OwlCarousel
                                    key={doc.id} // Adding key to force re-render
                                    nav={false}
                                    dots={false}
                                    loop
                                    autoplay
                                    autoplayTimeout={3000}
                                    items={1}
                                  >
                                    {doc.images &&
                                      doc.images.map((imageUrl, index) => (
                                        <div key={index}>
                                          <img
                                            src={imageUrl}
                                            alt={`Product Image ${index}`}
                                          />
                                        </div>
                                      ))}
                                  </OwlCarousel>

                                  {user && user.role === "admin" ? (
                                    <div
                                      className={`${
                                        doc.status && doc.status == "inactive"
                                          ? "card-2-inactive-status"
                                          : "card-2-active-status"
                                      }`}
                                    >
                                      <div
                                        className={`${
                                          doc.status && doc.status == "inactive"
                                            ? "card-2-inactive-flag"
                                            : "card-2-active-flag"
                                        }`}
                                      ></div>
                                      <span>
                                        {doc.status && doc.status == "inactive"
                                          ? "InActive"
                                          : "Active"}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="corporateTwo-card-wishlist">
                                    <span class="material-symbols-outlined">
                                      favorite
                                    </span>
                                  </div>

                                  <div className="card-2-rating-info">
                                    <span>4.5</span>
                                    <FaStar color="#fca503" size={20} />
                                  </div>
                                </div>

                                <div className="card-2-info-container">
                          <div className="corporatetwo-card-2-title-info">
                            <h4
                              className="corporateTwo-card-2-title"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString(),
                              }}
                            ></h4>
                            <p className="corporateTwo-card-2-short-desc">
                              This is a short description of the main and this
                              is this is that product
                            </p>
                          </div>

                          <div className="card-2-price-info">
                            <div className="card-2-selling-price">
                              <h3>{document && document.symbol} 1,52,500</h3>
                            </div>

                            <div className="card-2-discount-info">
                              <p>
                                <del>
                                  {document && document.symbol} 2,25,599
                                </del>
                              </p>
                              <span>15% off</span>
                            </div>
                          </div>

                          <div className="card-2-important-info">
                            {isProductInCart(doc.id) ? (
                              <div className="corporateTwo-card-2-quantity">
                                <div>
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleDecrementQuantity(findCartItem(doc.id))}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={findCartItem(doc.id).quantity}
                                    readOnly
                                  />
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="card-2-cart-btn"
                                onClick={() => handleAddToCart(doc.id)}
                              >
                                <span>Cart</span>
                                <span class="material-symbols-outlined">
                                  shopping_cart
                                </span>
                              </button>
                            )}

                            <div className="card-2-cart-btn-container">
                              <Link
                                to={
                                  dbProductDetailsDocuments &&
                                  dbProductDetailsDocuments.status === true &&
                                  `/product-details/${doc.id}`
                                }
                              >
                                <button className="card-2-detail-btn">
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                  </div>
                )}
            </>
          )}
          {/* NEW ARRIVALS PRODUCTS END*/}

          {/* BELOW INACTIVE PRODUCTS START*/}
          <div>
            {showInactive ||
              (user && user.role === "admin" && (
                <>
                  <br />
                  <hr />
                  <div className="product-page-heading-title">
                    <h2
                      style={{
                        fontSize: "20px",
                        marginLeft: "0px",
                        color: "var(--heading-color)",
                      }}
                    >
                      Inactive Products
                    </h2>
                  </div>
                  <br />
                  <div className="row">
                    {/* <div className="col-6 col-sm-6 col-lg-3 services-cards-parent-div">
                      <div className="doctor-item ss">
                        <div className="doctor-top product-thumb-img d-flex align-items-center justify-content-center">
                          {selectedProductImage ? (
                            <img
                              src={URL.createObjectURL(selectedProductImage)}
                              alt="Product"
                              className="product-image"
                              style={{
                                opacity: isLoading ? 0.3 : 1,
                              }}
                            />
                          ) : (
                            <img
                              src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                              alt="Product"
                              className="product-image"
                              style={{
                                opacity: isLoading ? 0.3 : 1,
                              }}
                            />
                          )}
                          {isLoading && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80px",
                                height: "80px",
                              }}
                            >
                              <CircularProgressbar
                                value={uploadProgress}
                                text={`${Math.round(uploadProgress)}%`}
                                styles={buildStyles({
                                  textSize: "24px",
                                  pathColor: "var(--click-color)",
                                  textColor: "var(--click-color)",
                                })}
                              />
                            </div>
                          )}
                          <div className="status-button-container">
                            <div className="upload-button-container">
                              <input
                                type="file"
                                accept="image/*"
                                id="serviceimageInput"
                                onChange={handleProductImageChange}
                                style={{ display: "none" }}
                              />
                              <label
                                htmlFor="serviceimageInput"
                                style={{ cursor: "pointer" }}
                              >
                                Image
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="booking_services_name_haedings_parent">
                          <input
                            type="text"
                            value={productName}
                            onChange={handleNameChange}
                            required
                            placeholder="Product Name"
                            className="product_name_class"
                          />

                          {/* Category selection */}
                    {/* <select
                            value={selectedAddCategory}
                            onChange={handleCategoryChange}
                            required
                            className="category-dropdown"
                          >
                            <option value="">Select Category</option>
                            {productsCategories &&
                              productsCategories.map((cat) => (
                                <option key={cat.id} value={cat.category}>
                                  {cat.category}
                                </option>
                              ))}
                          </select> */}

                    {/* Subcategory selection */}
                    {/* <select
                            value={selectedAddSubCategory}
                            onChange={handleSubCategoryChange}
                            disabled={!selectedAddCategory}
                            required
                            className="subcategory-dropdown"
                          >
                            <option value="">Select Subcategory</option>
                            {subCategories &&
                              subCategories.map((subCat) => (
                                <option
                                  key={subCat.subCategoryName}
                                  value={subCat.subCategoryName}
                                >
                                  {subCat.subCategoryName}
                                </option>
                              ))}
                          </select>
                          <div className="booking_services_two_buttons">
                            <button
                              type="button"
                              className="booking_services_book_now_btn"
                              onClick={handleAddProduct}
                              disabled={isLoading}
                            >
                              {isLoading ? "Adding..." : "Add Product"}
                            </button>
                            <button
                              type="button"
                              className="d-flex booking_services_see_all_btn"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </div> */}
                    {/* {errorText && (
                            <div className="error-text">{errorText}</div>
                          )} */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div>  */}

                    {limitedProducts &&
                      limitedProducts.map((doc, index) =>
                        doc && doc.status === "inactive" ? (
                          <div className=" col-6 col-sm-6 col-lg-3 corporateTwo-cards-container">
                            <div className="corporateTwo-card-2">
                              <div className="card-2-image-container">
                                <OwlCarousel
                                  key={doc.id} // Adding key to force re-render
                                  nav={false}
                                  dots={false}
                                  loop
                                  autoplay
                                  autoplayTimeout={3000}
                                  items={1}
                                >
                                  {doc.images &&
                                    doc.images.map((imageUrl, index) => (
                                      <div key={index}>
                                        <img
                                          src={imageUrl}
                                          alt={`Product Image ${index}`}
                                        />
                                      </div>
                                    ))}
                                </OwlCarousel>

                                <div className="card-2-inactive-status">
                                  <div className="card-2-inactive-flag"></div>
                                  <span>Inactive</span>
                                </div>

                                <div className="corporateTwo-card-wishlist">
                                  <span class="material-symbols-outlined">
                                    favorite
                                  </span>
                                </div>

                                <div className="card-2-rating-info">
                                  <span>4.5</span>
                                  <FaStar color="#fca503" size={20} />
                                </div>
                              </div>

                              <div className="card-2-info-container">
                          <div className="corporatetwo-card-2-title-info">
                            <h4
                              className="corporateTwo-card-2-title"
                              dangerouslySetInnerHTML={{
                                __html: doc.name.toString(),
                              }}
                            ></h4>
                            <p className="corporateTwo-card-2-short-desc">
                              This is a short description of the main and this
                              is this is that product
                            </p>
                          </div>

                          <div className="card-2-price-info">
                            <div className="card-2-selling-price">
                              <h3>{document && document.symbol} 1,52,500</h3>
                            </div>

                            <div className="card-2-discount-info">
                              <p>
                                <del>
                                  {document && document.symbol} 2,25,599
                                </del>
                              </p>
                              <span>15% off</span>
                            </div>
                          </div>

                          <div className="card-2-important-info">
                            {isProductInCart(doc.id) ? (
                              <div className="corporateTwo-card-2-quantity">
                                <div>
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleDecrementQuantity(findCartItem(doc.id))}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={findCartItem(doc.id).quantity}
                                    readOnly
                                  />
                                  <button
                                    className="corporateTwo-card-2-btn"
                                    onClick={() => handleIncrementQuantity(findCartItem(doc.id))}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                className="card-2-cart-btn"
                                onClick={() => handleAddToCart(doc.id)}
                              >
                                <span>Cart</span>
                                <span class="material-symbols-outlined">
                                  shopping_cart
                                </span>
                              </button>
                            )}

                            <div className="card-2-cart-btn-container">
                              <Link
                                to={
                                  dbProductDetailsDocuments &&
                                  dbProductDetailsDocuments.status === true &&
                                  `/product-details/${doc.id}`
                                }
                              >
                                <button className="card-2-detail-btn">
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                            </div>
                          </div>
                        ) : null
                      )}

                    <div>
                      <button className="btn btn-primary" onClick={openModal}>
                        Add Product
                      </button>
                    </div>

                    {isModalOpen && (
                      <div className="modal-overlay">
                        <div className="modal-content">
                          <h2>Add New Product</h2>
                          {/* Add your form fields here */}
                          <form className="add-product-form">
                            <span>
                              Upload Images{" "}
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "var(--click-color)",
                                }}
                              >
                                (Max 5)
                              </span>
                            </span>
                            <div className="image-upload-container">
                              {[1, 2, 3, 4, 5].map((num) => (
                                <div key={num} className="image-upload-box">
                                  <input
                                    type="file"
                                    id={`image-upload-${num}`}
                                    accept="image/*"
                                  />
                                  <label htmlFor={`image-upload-${num}`}>
                                    +
                                  </label>
                                </div>
                              ))}
                            </div>
                            <input
                              type="text"
                              placeholder="Product Name"
                              className="form-input"
                            />
                            <textarea
                              placeholder="Short Description"
                              className="form-input"
                              rows="3"
                            ></textarea>
                            <input
                              type="number"
                              placeholder="Price"
                              className="form-input"
                            />
                            <div className="dropdown-row">
                              <select className="form-input">
                                <option value="">Select Category</option>
                              </select>
                              <select className="form-input">
                                <option value="">Select Subcategory</option>
                              </select>
                            </div>
                            <div className="form-actions">
                              <button
                                type="button"
                                onClick={closeModal}
                                className="btn-cancel"
                              >
                                Cancel
                              </button>
                              <button type="submit" className="btn-save">
                                Save
                              </button>
                            </div>
                          </form>
                          {/* <button onClick={closeModal}>Close</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
          </div>

          {/* BELOW INACTIVE PRODUCTS END */}
        </div>
      </section>
    </div>
  );
};

export default Products;
